<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid"
      :width="40"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-container>
        <v-row class="mt-5 mb-0" no-gutters>
          <v-col cols="12" md="9">
            <v-text-field
                v-if="!isExists"
                v-model="currentItem.email"
                :rules="emailRules"
                label="Email"
                type="email"
                prepend-inner-icon="mdi-email"
                required
                class="mr-2"
                outlined
                dense
            />
            <g-p-auto-complete
                v-if="isExists"
                v-model="currentItem.email"
                apiPath="sales-counter/get-all-email"
                item-text="email"
                item-value="email"
                search-label="Select a Email"
                inner-icon="mdi-email"
                @clearSearchResult="handleClearSearchResult"
                @selectSearchResult="onSelectionsEmailHandler"
                class="mr-2"
                :outlined="true"
                :dense="true"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
                class="mt-1"
                v-model="isExists"
                label="Is Exists?"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model.number="currentItem.name"
                label="Agency Name"
                onclick="this.select()"
                prepend-inner-icon="mdi-account"
                required
                dense
                outlined
            />
            <static-autocomplete
                :item-value="currentItem.country"
                @onChangeHandler="countrySelectionHandler"
            />
            <v-text-field
                v-model.number="currentItem.phone"
                label="Phone"
                onclick="this.select()"
                prepend-inner-icon="mdi-cellphone"
                required
                dense
                outlined
            />
            <v-text-field
                v-model.number="currentItem.skype"
                label="Skype"
                onclick="this.select()"
                prepend-inner-icon="mdi-skype-business"
                required
                dense
                outlined
            />
            <v-text-field
                v-model.number="currentItem.whatsapp"
                label="Whatsapp"
                onclick="this.select()"
                prepend-inner-icon="mdi-whatsapp"
                required
                dense
                outlined
            />
            <v-text-field
                v-model.number="currentItem.discount"
                :rules="numberRule"
                class="right-align-text"
                label="Percentage of Discount"
                onclick="this.select()"
                prepend-inner-icon="%"
                required
                type="number"
                dense
                outlined
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import GPAutoComplete from "../GPAutoComplete";
import StaticAutocomplete from "../StaticAutocomplete";

export default {
  name: 'AgencyClientPopup',
  components: {StaticAutocomplete, GPAutoComplete, BasePopup},
  mixins: [PopupMixin],
  data() {
    return {
      isExists: false
    }
  },
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Agency Client'
          : 'Add Agency Client'
    }
  },
  methods: {
    onSelectionsEmailHandler(selection) {
      this.currentItem.email = selection.email;
    }
  }
}
</script>

<style scoped>
</style>
