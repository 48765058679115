<template>
  <v-autocomplete
      v-model="search"
      :items="countries"
      item-value="code"
      item-text="name"
      label="Country"
      no-data-text="No country found"
      dense
      outlined
      clearable
      @change="onChangeHandler"
  ></v-autocomplete>

</template>

<script>
import {countries} from "country-list-json";

export default {
  name: "StaticAutocomplete",
  data(){
    return{
      countries: countries,
      search: ''
    }
  },
  props:{
    itemValue: {
      type: String,
      default: ''
    },
  },
  watch: {
    itemValue: {
      handler: function (value) {
        this.search = value;
      },
      immediate: true
    }
  },
  methods: {
    onChangeHandler() {
      this.$emit('onChangeHandler', this.search);
    }
  }
}
</script>

<style scoped>

</style>