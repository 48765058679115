<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        title="Agency Client List"
        icon="mdi-alpha-a-circle"
        addButtonText="Agency Client"
        stateendpoint="fixClient.fixClients"
        :basic-data="basicData"
        :actions="actions"
        :param1="2"
    />
  </div>
</template>
<script>
import ListPage from "../../components/ListPage";
import AgencyClientPopup from "../../components/popups/AgencyClientPopup";

export default {
  name: 'AgencyClients',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Agency Clients | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, AgencyClientPopup},
  data() {
    return {
      headers: [
        {
          text: 'Agency Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Country',
          value: 'country'
        },
        {
          text: 'Phone',
          value: 'phone'
        },
        {
          text: 'Skype',
          value: 'skype'
        },
        {
          text: 'Whatsapp',
          value: 'whatsapp'
        },
        {
          text: 'Percentage of Discount(%)',
          value: 'discount'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'action'},
      ],
      basicData: {
        type: 2,
        price: 0,
        linkPrice: 0,
        adultPrice: 0,
        discount: 0
      },
      actions: {
        load: 'loadFixClients',
        create: 'storeFixClient',
        update: 'updateFixClient',
        remove: 'removeFixClient',
        destroy: 'destroyFixClient'
      }
    }
  },
  computed: {
    popupComponent() {
      return AgencyClientPopup;
    }
  }
}
</script>
